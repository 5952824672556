import fileDownload from "js-file-download";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoEyeOutline, IoLogoWhatsapp } from "react-icons/io5";
import { MdOutlineFileDownload } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ModalAlt from "../../components/modal/ModalAlt";

import { SlShare } from "react-icons/sl";
import { ERROR_MSG, ROLE } from "../../constants/common";
import { RESET_LOADER, SET_LOADER } from "../../constants/contexConstant";
import {
    DOWNLOAD_LEASE_PROPOSAL_DATA_ENDPOINT,
    DOWNLOAD_PURCHASE_PROPOSAL_DATA_ENDPOINT,
    GET_PLANT_BY_ID_ENDPOINT,
    LEASE_DETAILS,
    PROPOSAL,
    PROPOSAL_DOCUMENT_META_ENDPOINT,
    PURCHASE_DETAILS,
    SALES_PROPOSAL_BASE_ENDPOINT,
    SHARE_PROPOSAL_TO_WHATSAPP_ENDPOINT,
    SURVEY_VIEW_LABEL,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { checkIsAllowed } from "../../helpers/helpers";
import useFileUploadAndRemove from "../../hooks/useFileUploadAndRemove";
import { copy, share, tap } from "../../resources/js/icons";
import SolarLeaseTemplate from "../../resources/templates/SolarLeaseTemplate";
import SolarPurchaseTemplate from "../../resources/templates/SolarPurchaseTemplate";
import request from "../../services/request";
import H3 from "../../shared/typography/H3";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import CustomButton from "../../shared/ui/CustomButton";
import RadioButton from "../../shared/ui/Form/RadioButton";
import TextField from "../../shared/ui/Form/TextField";
import TableComponent from "../../shared/ui/Table";
import MemberInput from "./MemberInput";
const columns = ["Description", "Plan Details"];
const LEASE = "Lease";
const PURCHASE = "Purchase";

// const createAndSendPDF = async (leaseRef) => {
//     try {
//         const style = document.createElement("style");
//         style.textContent = `
//             @import url('https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css');
//         `;
//         document.head.appendChild(style);

//         const leasePDFOptions = {
//             margin: 1,
//             filename: "SolarLeaseProposal.pdf",
//             image: { type: "jpeg", quality: 0.98 },
//             html2canvas: { scale: 2 },
//             jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
//         };

//         await html2pdf().set(leasePDFOptions).from(leaseRef.current).save();

//         // Clean up: Remove the <style> element after PDF generation
//         document.head.removeChild(style);

//         console.log("Solar Lease PDF downloaded successfully");
//     } catch (error) {
//         console.error("Error downloading Solar Lease PDF", error);
//     }
// };

const ProposalTable = ({
    currentTab,
    selected,
    tableData,
    checkedOptions,
    options,
    connectionType,
    handleAdmins,
    admins,
    url,
}) => {
    const history = useHistory();
    const { state, dispatch } = useContextState();
    const { id } = useParams();
    const componentRef = useRef();
    const [purchasedataTemplate, setPurchaseDataTemplate] = useState(null);
    const [leasedataTemplate, setLeaseDataTemplate] = useState(null);
    const [showLeaseModal, setShowLeaseModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [additionalInput, setAdditionalInputs] = useState([
        { adminName: "", adminPhone: "" /*adminRole: "" */ },
    ]);
    useEffect(() => {
        setAdditionalInputs([
            { adminName: "", adminPhone: "" /*adminRole: "" */ },
        ]);
    }, [state]);
    const [showRevModal, setShowRevModal] = useState(false);
    const [step, setStep] = useState(0);
    const [buttonTxt, setButtonTxt] = useState("Approved");
    const [category, setCategory] = useState("");
    const [subject, setSubject] = useState("");
    const [feedback, setFeedback] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [description, setDescription] = useState("");
    const TableData = [
        [
            "Proposed Solar Capacity To Be Installed (KWp)",
            <P className="font-semibold">170</P>,
        ],
        [
            "Bill To Be Paid Every Month",
            <P className="font-semibold">90,000</P>,
        ],
        ["Token Amount To Be Paid", <P className="font-semibold">20,000</P>],
        [
            "Tentative Installation Date",
            <P className="font-semibold">03-March-2023</P>,
        ],
    ];
    const handleModal = () => {
        setShowModal((showModal) => !showModal);
    };
    const handleRevModal = () => {
        setShowRevModal((showModal) => !showModal);
    };
    const [formErrors, setFormErrors] = useState({
        category: "",
        subject: "",
        feedback: "",
    });
    const validateFeedbackForm = () => {
        const newErrors = {};
        if (!category) newErrors.category = "Category is required";
        if (!subject) newErrors.subject = "Subject is required";
        if (!feedback || feedback.length < 10)
            newErrors.feedback = "Feedback must be at least 10 characters long";

        setFormErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleFeedbackSubmit = () => {
        if (!validateFeedbackForm()) {
            return;
        }

        // Reset fields and close modal (if applicable)
        setCategory("");
        setSubject("");
        setFeedback("");
        setFormErrors({});
        handleRevModal(); // Closing the modal
    };

    const {
        file,
        setFile,
        uploadedDoc,
        uploading,
        setUploadedDoc,
        handleFileChange,
        removeFile,
    } = useFileUploadAndRemove();
    const [formData, setFormData] = useState({
        bankAccountName: "",
        bankAccountNumber: "",
        bankIFSCCode: "",
    });
    const [errors, setErrors] = useState({
        accountName: "",
        accountNumber: "",
        ifscCode: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleFeedbackChange = (e) => {
        const { name, value } = e.target;
        if (name === "category") setCategory(value);
        if (name === "subject") setSubject(value);
        if (name === "feedback") setFeedback(value);
    };
    const textToCopy = `Join Our Solar Community.  

    You can earn up to 20% returns, and your society will benefit from significant savings on electricity bills. It's a win-win for everyone!  
    Together, our small contributions can make a big difference.  
    
    ${url}
    `;

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            toast.success("Text copied to clipboard!");
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    };
    const validateForm = () => {
        const newErrors = {};
        if (!formData.bankAccountName) {
            newErrors.bankAccountName = "Account name is required";
        }
        if (!formData.bankAccountNumber) {
            newErrors.bankAccountNumber = "Account number is required";
        } else if (!/^\d+$/.test(formData.bankAccountNumber)) {
            newErrors.bankAccountNumber = "Account number must be numeric";
        }
        if (!formData.bankIFSCCode) {
            newErrors.bankIFSCCode = "IFSC code is required";
        } else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(formData.bankIFSCCode)) {
            newErrors.bankIFSCCode = "Invalid IFSC code format";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        if (step === 1 && !validateForm()) {
            return;
        }

        if (options[currentTab] === "Lease" && step === 0) {
            setStep(1);
            setButtonTxt("Submit");
        } else {
            setStep(2);
            setButtonTxt("Close");
        }
    };
    const fetchData = async () => {
        try {
            const endpoint = `${GET_PLANT_BY_ID_ENDPOINT}/${id}`;
            const data = await request.authGet({ endpoint });
            handleAdmins(data?.data?.plant?.admins);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const handleInvite = async () => {
        const modifiedInput = additionalInput.map((input) => ({
            ...input,
            adminPhone: input.adminPhone ? `91${input.adminPhone}` : "",
        }));
        try {
            const endpoint = `${SALES_PROPOSAL_BASE_ENDPOINT}/lease/${id}/enable_investment`;
            dispatch({ type: SET_LOADER });
            await request.authPost({
                endpoint,
                body: modifiedInput,
            });
            fetchData();
            dispatch({ type: RESET_LOADER });
            toast.success("Invite Sent!");
        } catch (error) {
            dispatch({ type: RESET_LOADER });
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const leaseEndpoint = `${PROPOSAL_DOCUMENT_META_ENDPOINT}/lease/${id}/${selected?.id}`;

                const purchaseEndpoint = `${PROPOSAL_DOCUMENT_META_ENDPOINT}/purchase/${id}/${selected?.id}`;

                try {
                    dispatch({ type: SET_LOADER });
                    const leasedata = await request.authGet({
                        endpoint: leaseEndpoint,
                    });
                    dispatch({ type: RESET_LOADER });
                    setLeaseDataTemplate(leasedata?.data?.data);
                    checkedOptions("Lease", true);
                } catch (error) {
                    dispatch({ type: RESET_LOADER });
                    checkedOptions("Lease", false);
                    console.error("Error fetching lease data:", error);
                }

                try {
                    dispatch({ type: SET_LOADER });
                    const purchasedata = await request.authGet({
                        endpoint: purchaseEndpoint,
                    });
                    dispatch({ type: RESET_LOADER });
                    setPurchaseDataTemplate(purchasedata?.data?.data);
                    checkedOptions("Purchase", true);
                } catch (error) {
                    dispatch({ type: RESET_LOADER });
                    checkedOptions("Purchase", false);
                    console.error("Error fetching purchase data:", error);
                }
            } catch (error) {
                dispatch({ type: RESET_LOADER });
                console.error("Error fetching data:", error);
            }
        };

        if (selected) {
            fetchData().catch(console.error);
        }
    }, [selected]);

    // const leaseRef = useRef();
    // const purchaseRef = useRef();

    const [showSharingModal, setShowSharingModal] = useState(false);
    const [selectedShare, setSelectedShare] = useState(PURCHASE);
    const [whatsAppLoading, setWhatsAppLoading] = useState(false);

    const handleShareToWhatsapp = useCallback(async () => {
        let body = {
            plantId: id,
            proposalId: selected?.id,
            proposalType: selectedShare.toUpperCase(),
        };
        try {
            setWhatsAppLoading(true);
            let endpoint = SHARE_PROPOSAL_TO_WHATSAPP_ENDPOINT;
            await request.authPost({ endpoint, body });
            setWhatsAppLoading(false);
            toast.success(
                `You have successfully shared ${selectedShare} proposal with proposal id: #${selected?.id} to Whatsapp`
            );
            setShowSharingModal(false);
            setSelectedShare(PURCHASE);
        } catch (error) {
            setWhatsAppLoading(false);
            toast.error(typeof error === "string" ? error : ERROR_MSG);
            setShowSharingModal(false);
            setSelectedShare(PURCHASE);
        }
    }, [id, selected?.id, selectedShare]);
    const handleQuit = () => {
        setAdditionalInputs([
            { adminName: "", adminPhone: "" /*adminRole: "" */ },
        ]);
        setShowLeaseModal(false);
    };
    const downloadProposalDocument = async () => {
        let basePath =
            options[currentTab] === PURCHASE
                ? DOWNLOAD_PURCHASE_PROPOSAL_DATA_ENDPOINT
                : DOWNLOAD_LEASE_PROPOSAL_DATA_ENDPOINT;

        try {
            dispatch({ type: SET_LOADER });
            let endpoint = `${basePath}/${id}/${selected?.id}`;
            let res = await request.authGet({
                endpoint,
                checkStatusCode: true,
            });
            let file = await res.blob();
            fileDownload(
                file,
                res?.headers?.get("x-custom-filename") || "proposal.pdf"
            );
            dispatch({ type: RESET_LOADER });
        } catch (error) {
            toast.error(typeof error === "string" ? error : ERROR_MSG);
            dispatch({ type: RESET_LOADER });
        }
    };
    return (
        <>
            <div className="bg-gray-170 w-full md:w-[65%] lg:w-[75%] xl:w-[80%] rounded-2xl p-1 md:p-2">
                <div className="flex flex-wrap sm:flex-nowrap gap-1 justify-between items-center">
                    <div className="flex gap-1 items-center">
                        <div className="bg-blue-200  rounded-lg px-2 py-0.5">
                            <P className="font-thin">
                                Proposal ID #{selected?.id}
                            </P>
                        </div>
                        <div className="bg-blue-200  rounded-lg px-2 py-0.5">
                            <P className="font-thin"># {connectionType}</P>
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        {checkIsAllowed(state?.user?.roles, [
                            ROLE.SUPER_ADMIN,
                            ROLE.SALES_MANAGER,
                        ]) &&
                            options[currentTab] === "Lease" && (
                                <CustomButton
                                    icon={<SlShare />}
                                    onClick={() => setShowShareModal(true)}
                                />
                            )}
                        {checkIsAllowed(state?.user?.roles, [
                            ROLE.SUPER_ADMIN,
                            ROLE.SALES_MANAGER,
                        ]) &&
                            options[currentTab] === "Lease" && (
                                <CustomButton
                                    icon={
                                        <img
                                            src={tap}
                                            alt="tap"
                                            className="w-1.8"
                                        />
                                    }
                                    onClick={() => setShowLeaseModal(true)}
                                />
                            )}
                        <CustomButton
                            icon={<IoLogoWhatsapp />}
                            onClick={() => setShowSharingModal(true)}
                        />
                        <CustomButton
                            icon={<IoEyeOutline />}
                            onClick={() => {
                                options[currentTab] === "Lease"
                                    ? history.push(
                                          `${LEASE_DETAILS}/${id}/${selected?.id}`
                                      )
                                    : history.push(
                                          `${PURCHASE_DETAILS}/${id}/${selected?.id}`
                                      );
                            }}
                        />
                        <CustomButton
                            icon={<MdOutlineFileDownload />}
                            onClick={downloadProposalDocument}
                        />
                    </div>
                </div>
                <div className="bg-white border-2 border-dashed rounded-xl px-1 py-1 md:px-2 md:py-1.5 mt-2 border-gray-220">
                    <P className="mb-1">Solar Lease Plan Details</P>
                    {/* @TODO => Use this table @habib610 Sat February 01,2025 */}
                    <TableComponent
                        columns={columns}
                        data={tableData}
                        headerColor="bg-blue-300"
                        oddRowColor="bg-gray-170"
                        additionalRowClassName="rounded-xl"
                        borderStyle="border-collapse border border-gray-200"
                        fontSize="text-xs md:text-sm"
                    />
                </div>
                <div className="flex justify-between items-center mt-2">
                    <CustomButton
                        color="blue"
                        text="Survey"
                        onClick={() =>
                            history.push(`/${SURVEY_VIEW_LABEL}/${id}`)
                        }
                    />
                    <div className="flex items-center gap-1">
                        {checkIsAllowed(state?.user?.roles, [
                            ROLE.SUPER_ADMIN,
                            ROLE.SALES_MANAGER,
                        ]) && (
                            <CustomButton
                                text="Revise"
                                onClick={() =>
                                    history.push(
                                        `${PROPOSAL}/${id}/${selected?.id}/revision`
                                    )
                                }
                            />
                        )}
                    </div>
                </div>
                <div className="hidden">
                    {options[currentTab] === "Lease" && leasedataTemplate ? (
                        <SolarLeaseTemplate
                            ref={componentRef}
                            data={leasedataTemplate}
                        />
                    ) : (
                        <SolarPurchaseTemplate
                            ref={componentRef}
                            data={purchasedataTemplate}
                        />
                    )}
                </div>
                {/* <ModalAlt
                isOpen={showModal}
                backgroundColor="transparent"
                onClose={handleModal}
                innerTimes={false}
                topPadding={"30px"}
            >
                <div className=" mx-auto bg-white rounded-1.5 shadow-md p-2 md:py-4.6 md:px-3">
                    {step === 0 ? (
                        <>
                            <div>
                                <H4>Final Proposal Summary</H4>
                            </div>
                            {options[currentTab] === "Lease" && (
                                <>
                                    <P className="mb-1">Upload files</P>
                                    <label
                                        role="button"
                                        className="border border-blue-400 rounded-lg p-1 w-full text-center flex items-center justify-center cursor-pointer mb-0.4"
                                        htmlFor="fileUploadInput"
                                    >
                                        <P className="flex items-center gap-1">
                                            <BsUpload />
                                            Click here to upload file
                                        </P>
                                    </label>
                                    <input
                                        type="file"
                                        id="fileUploadInput"
                                        name="fileUploadInput"
                                        onClick={(e) => (e.target.value = null)}
                                        onChange={handleFileChange}
                                        className="hidden"
                                    />
                                    <div className=" max-h-0[100px] lg:max-h-[150px] overflow-y-auto">
                                        {file.length > 0 &&
                                            file.map((doc, index) => (
                                                <div
                                                    key={index}
                                                    className="flex justify-between items-center border border-blue-400 rounded-lg p-1 mb-0.5"
                                                >
                                                    <P className="text-secondary">
                                                        {doc.name}
                                                    </P>
                                                    <button
                                                        onClick={() =>
                                                            removeFile(index)
                                                        }
                                                        className="text-red-500 hover:text-red-700"
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            ))}
                                    </div>
                                </>
                            )}
                            <DataTable
                                data={TableData}
                                rowClassName={"w-full p-2"}
                                columnClassName={
                                    "text-base text-left text-nowrap p-2"
                                }
                                rowStyles={{
                                    boxShadow:
                                        "inset 0.5px -4px rgba(133, 133, 133, 0.07)",
                                }}
                            />
                        </>
                    ) : step === 1 ? (
                        <>
                            <div>
                                <H4>Bank Information</H4>
                            </div>
                            <TextField
                                label={"Bank Account Name*"}
                                value={formData.bankAccountName}
                                onChange={handleChange}
                                name="bankAccountName"
                                type={"text"}
                                errorMsg={errors.accountName}
                                placeholder={"Enter Bank Account Name"}
                                required={true}
                            />
                            <TextField
                                label={"Bank Account Number*"}
                                value={formData.bankAccountNumber}
                                onChange={handleChange}
                                name="bankAccountNumber"
                                type={"text"}
                                errorMsg={errors.accountNumber}
                                placeholder={"Enter Bank Account Number"}
                                required={true}
                            />
                            <TextField
                                label={"Bank IFSC Code*"}
                                value={formData.bankIFSCCode}
                                onChange={handleChange}
                                name="bankIFSCCode"
                                type={"text"}
                                errorMsg={errors.ifscCode}
                                placeholder={"Enter Bank IFSC Code"}
                                required={true}
                            />
                        </>
                    ) : (
                        <div>
                            <H4 className="text-green">Success</H4>
                        </div>
                    )}
                    <Button
                        onClick={() => handleSubmit()}
                        className="w-full mt-3"
                    >
                        {buttonTxt}
                    </Button>
                </div>
            </ModalAlt> */}
            </div>
            <ModalAlt
                width={["100%", "80%", "80%"]}
                topPadding="0"
                overlayClass="h-[100vh] flex "
                times={false}
                isOpen={showLeaseModal}
                onClose={() => {
                    setShowLeaseModal((prev) => !prev);
                    handleQuit();
                }}
            >
                <H4 className="my-1 font-semibold">Enable Lease Model Plan</H4>
                <p className="text-sm font-medium mb-0.5">
                    Enter Admin Details Below
                </p>

                <div className="max-h-[500px] overflow-y-scroll">
                    <div className="">
                        {admins?.map((inputItem, index) => (
                            <div
                                key={index}
                                className="flex flex-wrap gap-1 md:gap-2 mb-2"
                            >
                                <div className="w-[90%] xs:w-[91%] sm:w-[94%] md:w-[45%] lg:w-[47%]  ">
                                    <TextField
                                        name="adminName"
                                        value={inputItem.name}
                                        disabled={true}
                                    />
                                </div>
                                <div className="w-[90%] xs:w-[91%] sm:w-[94%] md:w-[45%] lg:w-[46%] xl:w-[47%] ">
                                    <TextField
                                        name="adminPhone"
                                        placeholder="Admin Phone Number"
                                        value={inputItem.phone?.slice(2)}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <MemberInput
                        inputs={additionalInput}
                        handleInvite={handleInvite}
                        setInputs={setAdditionalInputs}
                        handleQuit={handleQuit}
                        admins={admins}
                    />
                </div>
            </ModalAlt>
            <ModalAlt
                width={["100%", "500px", "500px"]}
                topPadding="0"
                overlayClass="h-[100vh] flex items-center"
                times={false}
                isOpen={showShareModal}
                onClose={() => setShowShareModal((prev) => !prev)}
            >
                <div className="mx-3 flex flex-col gap-2">
                    <H4 className="mt-2 text-center">Invite Members</H4>
                    <div className="flex justify-center items-center">
                        <img src={share} alt="share" className="w-8 h-8" />
                    </div>
                    <P>
                        Invite your friends and family to invest in the solar
                        project. Together, our small contributions can make a
                        big difference.
                    </P>
                    <div className="flex w-full  justify-between  items-center  gap-1">
                        <div className="text-base w-[80%] bg-gray-110 border border-blue-300  rounded-sm">
                            <div className=" px-0.5 line-clamp-1 text-center text-xs xs:text-sm md:text-sm xl:text-sm font-normal font-vietnam  py-0.8 text-blue ">
                                <a>{url}</a>
                            </div>
                        </div>

                        <div
                            className=" rounded-sm   border p-0.5 border-gray-550 flex items-center justify-center"
                            role="button"
                            onClick={copyToClipboard}
                        >
                            <img src={copy} alt="copy" className="h-3 w-3" />
                        </div>
                    </div>
                </div>
            </ModalAlt>
            <ModalAlt
                width={["100%", "500px", "500px"]}
                topPadding="0"
                overlayClass="h-[100vh] flex items-center"
                times={false}
                isOpen={showSharingModal}
                onClose={() => setShowSharingModal((prev) => !prev)}
            >
                <H3 className="mt-2">
                    Which proposal would you like to share?
                </H3>
                <div className="flex items-center gap-2 my-2">
                    <RadioButton
                        name="whatsappShare"
                        value={PURCHASE}
                        checked={selectedShare === PURCHASE}
                        onChange={() => setSelectedShare(PURCHASE)}
                        label={PURCHASE}
                    />
                    <RadioButton
                        name="whatsappShare"
                        value={LEASE}
                        checked={selectedShare === LEASE}
                        onChange={() => setSelectedShare(LEASE)}
                        label={LEASE}
                    />
                </div>
                <div className="flex justify-start ">
                    <Button
                        className="mt-2 w-full  md:w-6/6"
                        disabled={whatsAppLoading}
                        isLoading={whatsAppLoading}
                        onClick={handleShareToWhatsapp}
                    >
                        Share on Whatsapp
                    </Button>
                </div>
            </ModalAlt>
        </>
    );
};

export default ProposalTable;
