import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ROLE } from "../../constants/common";
import { RESET_LOADER, SET_LOADER } from "../../constants/contexConstant";
import { connectionTypeToCategory } from "../../constants/InvestmentConstant";
import { useContextState } from "../../context/ContextProvider";
import { checkIsAllowed } from "../../helpers/helpers";
import { aboutUsBg } from "../../resources/js/images";
import request from "../../services/request";
import P from "../../shared/typography/P";
import Hero from "../home/Hero";
import HeroDetails from "./HeroDetails";
import ProposalContent from "./ProposalContent";

const buildTableData = (
    existingItems,
    costKw,
    solarPlantSize,
    GST,
    isLease,
    leaseTable
) => {
    let additional = existingItems?.additionalItems || [];
    let totalWithAdditional =
        (additional?.length && additional?.length !== 0
            ? additional.reduce((prev, el) => prev + parseInt(el.itemPrice), 0)
            : 0) +
        parseInt(costKw * solarPlantSize) +
        GST;

    let initialData = isLease
        ? [
              {
                  Description: "Fixed Monthly Rental",
                  "Plan Details": (
                      <P className="font-semibold">
                          {leaseTable?.fixedMonthlyRental}
                      </P>
                  ),
              },
              {
                  Description: "Bank Guarantee",
                  "Plan Details": (
                      <P className="font-semibold">
                          {leaseTable?.bankGuarantee}
                      </P>
                  ),
              },
              {
                  Description: "Savings on Existing Bills",
                  "Plan Details": (
                      <P className="font-semibold">
                          {leaseTable?.savingsOnExistingBillsPercentage} %
                      </P>
                  ),
              },
          ]
        : [
              {
                  Description: (
                      <P className="text-secondary ">
                          Solar Plant Cost:
                          <span className="font-medium">
                              {`${solarPlantSize} KWp`}
                          </span>{" "}
                          <span className="text-sm">
                              @{parseInt(costKw).toLocaleString()}
                              /KWp
                          </span>
                      </P>
                  ),

                  "Plan Details": (
                      <P className="font-semibold">
                          {parseInt(costKw * solarPlantSize).toLocaleString()}
                      </P>
                  ),
              },
              {
                  Description: "GST @ 13.8%",
                  "Plan Details": (
                      <P className="font-semibold">{GST.toLocaleString()}</P>
                  ),
              },

              ...(additional?.length && additional.length !== 0
                  ? additional.map((item) => ({
                        Description: (
                            <P className="text-secondary 2xl:text-base ">
                                {item.itemDescription}
                                {item.itemGst && Number(item.itemGst) !== 0 && (
                                    <span className="text-primary text-xs block">
                                        GST @{" "}
                                        <span className=" font-medium">
                                            {item.itemGst}%
                                        </span>
                                    </span>
                                )}
                            </P>
                        ),
                        "Plan Details": (
                            <P className="font-semibold">
                                {parseInt(item.itemPrice).toLocaleString()}
                            </P>
                        ),
                    }))
                  : []),

              {
                  Description: (
                      <P className="text-secondary font-semibold xl:text-lsm">
                          Total Amount
                      </P>
                  ),
                  "Plan Details": (
                      <P className="font-semibold">
                          {totalWithAdditional.toLocaleString()}
                      </P>
                  ),
              },
          ];

    return initialData;
};
const Proposal = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const { id } = useParams();
    const [currentProposal, setCurrentProposal] = useState(null);
    const [proposalData, setProposalData] = useState(null);
    const [selected, setSelected] = useState(null);
    const [costKw, setCostKw] = useState(0);
    const [checkedState, setCheckedState] = useState({
        purchase: true,
        lease: true,
        loan: true,
    });

    const options = Object.keys(checkedState)
        .filter((key) => checkedState[key])
        .map((option) => option.charAt(0).toUpperCase() + option.slice(1));

    const [solarPlantSize, setSolarPlantSize] = useState(0);
    const [subsidyAmount, setSubsidyAmount] = useState(0);
    const [GST, setGST] = useState(0);
    const [upfront, setUpfront] = useState(0);
    const [leaseTable, setLeaseTable] = useState([]);
    const handleTabClick = (index) => {
        setCurrentTab(index);
    };
    const { state, dispatch } = useContextState();
    const [solarData, setSolarData] = useState(null);
    const [connectionType, setConnectionType] = useState(null);
    const [totalUnits, setTotalUnits] = useState(null);
    const [consumersBill, setConsumersBill] = useState(null);
    const [admins, setAdmins] = useState([]);
    const [url, setUrl] = useState("");
    const handleSelectedProposal = (item) => {
        setSelected(item);
    };
    const handleAdmins = (admins) => {
        setAdmins(admins?.admins);
        setUrl(admins?.url);
    };

    useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: SET_LOADER });
            const endpoint = `/sales/proposal/${id}/all`;
            const data = await request.authGet({ endpoint });

            setProposalData(data.data.proposal);
            const foundProposal = data.data.proposal.find(
                (proposal) => proposal.isCurrentVersion === true
            );

            setSolarData(foundProposal ? JSON.parse(foundProposal?.data) : "");
            setConnectionType(foundProposal?.connectionType);
            setTotalUnits(foundProposal?.totalUnits);
            setConsumersBill(foundProposal?.consumersBill);
            setCheckedState({
                Purchase: foundProposal
                    ? foundProposal?.purchase !== "REJECTED"
                    : true,
                Lease: foundProposal
                    ? foundProposal?.lease !== "REJECTED"
                    : true,
                Loan: foundProposal ? foundProposal?.loan !== "REJECTED" : true,
            });
            setSelected(foundProposal);
            setCurrentProposal(foundProposal);
            dispatch({ type: RESET_LOADER });
        };
        fetchData();
    }, [dispatch, id]);

    useEffect(() => {
        setSolarData(selected ? JSON.parse(selected?.data) : "");
        setConnectionType(connectionTypeToCategory[selected?.category]);
        setTotalUnits(selected?.consumerTotalUnits);
        setConsumersBill(selected?.consumerBill);
    }, [selected]);

    const checkedOptions = (field, value) => {
        setCheckedState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    /* @TODO => update this as well @habib610 Sat February 01,2025 */
    const calculateSubsidy = useCallback(
        (size) => {
            if (connectionType === "Residential Society") {
                let subAmt =
                    solarData?.government_subsidy["residential-society"][
                        "0-500"
                    ] * size;
                setSubsidyAmount(subAmt.toLocaleString());
            } else if (connectionType === "Individual Homes") {
                let subAmt;
                const subsidyData =
                    solarData?.government_subsidy["individual-homes"];
                if (subsidyData) {
                    Object.keys(subsidyData).forEach((range) => {
                        const [min, max] = range.split("-").map(Number);
                        if (size >= min && size <= max) {
                            subAmt = subsidyData[range];
                        }
                    });
                }
                if (subAmt) {
                    setSubsidyAmount(subAmt.toLocaleString());
                }
            }
        },
        [connectionType, solarData?.government_subsidy]
    );

    const calculateTotalCost = useCallback(
        (size) => {
            let costPerKW;
            const pricingData =
                connectionType === "Residential Society" ||
                connectionType === "Individual Homes"
                    ? solarData?.pricing?.loan_and_purchase?.subsidy
                    : solarData?.pricing?.loan_and_purchase["non-subsidy"];

            if (pricingData) {
                Object.keys(pricingData).forEach((range) => {
                    const [min, max] = range.split("-").map(Number);
                    if (size > min && size <= max) {
                        costPerKW = pricingData[range];
                    }
                });
            }

            if (costPerKW) {
                setCostKw(parseInt(costPerKW));
                const savemt = size * costPerKW;
                const totalCost = savemt * 1.138;
                setGST(parseInt(savemt * 0.138));
                setUpfront(totalCost * 0.3);
            } else {
                console.error(
                    "Could not find appropriate range for the provided size"
                );
            }
        },
        [connectionType, solarData?.pricing?.loan_and_purchase]
    );

    const calculateLeasePricingTable = useCallback(
        (solarPlantSize, averageMonthlyBill) => {
            const investorRate = Number(
                solarData?.charges["residential-society"].investor_rate["5Y"]
            );
            const hypersrotRate = Number(
                solarData?.charges["residential-society"].hypersrot_rate.per_KW
            );

            const fixedMonthlyRental =
                (investorRate + hypersrotRate) * 120 * solarPlantSize;

            const bankGuarantee = fixedMonthlyRental * 4;
            const savingsOnExistingBillsPercentage =
                ((averageMonthlyBill - fixedMonthlyRental) /
                    averageMonthlyBill) *
                100;

            const governmentSubsidy =
                solarData?.government_subsidy["residential-society"]["0-500"] *
                solarPlantSize;

            const planDuration = Number(selected?.plantDuration) * 12; // Assuming a plan duration of 5 years
            const effectiveSavingsPerMonth =
                ((consumersBill -
                    averageMonthlyBill +
                    governmentSubsidy / planDuration) /
                    averageMonthlyBill) *
                100;

            return {
                fixedMonthlyRental: fixedMonthlyRental.toLocaleString(),
                bankGuarantee: bankGuarantee.toLocaleString(),
                savingsOnExistingBillsPercentage:
                    savingsOnExistingBillsPercentage.toFixed(2),
            };
        },
        [
            consumersBill,
            selected?.plantDuration,
            solarData?.charges,
            solarData?.government_subsidy,
        ]
    );

    useEffect(() => {
        if (totalUnits > 0) {
            const size = parseInt(totalUnits / 120);
            setSolarPlantSize(size.toFixed(2));
            calculateSubsidy(size.toFixed(2));
            calculateTotalCost(size.toFixed(2));
            setLeaseTable(
                calculateLeasePricingTable(size, Number(consumersBill))
            );
        }
    }, [
        calculateLeasePricingTable,
        calculateSubsidy,
        calculateTotalCost,
        consumersBill,
        totalUnits,
    ]);

    const billWithoutGST = useMemo(() => {
        return isNaN(consumersBill)
            ? Number(selected?.consumerBill) / 1.18
            : Number(consumersBill) / 1.18;
    }, [consumersBill, selected?.consumerBill]);

    let isLease = options[currentTab] === "Lease";
    let existingItems = selected?.id
        ? proposalData.find((item) => item.id === selected?.id)
        : null;
    return (
        <div>
            <Hero
                containerClass={"mb-5 px-0 xl:mb-5 2xl:mb-5"}
                wrapperClass="h-[550px] sm:h-[400px] md:h-[450px] xl:h-[350px]"
                align={"items-end px-2 relative bottom-0"}
                content={
                    <HeroDetails
                        currentTab={currentTab}
                        handleTabClick={handleTabClick}
                        selected={selected}
                        upfront={upfront}
                        subsidyAmount={subsidyAmount}
                        billWithSolar={consumersBill}
                        solarPlantSize={solarPlantSize}
                        admins={admins}
                        options={options}
                        handleAdmins={handleAdmins}
                        billWithoutGST={consumersBill}
                        connectionType={connectionType}
                        isAdmin={checkIsAllowed(state?.user?.roles, [
                            ROLE.SUPER_ADMIN,
                            ROLE.SALES_MANAGER,
                        ])}
                    />
                }
                url={aboutUsBg}
            />

            <ProposalContent
                currentTab={currentTab}
                checkedOptions={checkedOptions}
                currentProposal={currentProposal}
                proposalData={proposalData}
                connectionType={connectionType}
                selected={selected}
                admins={admins}
                url={url}
                handleAdmins={handleAdmins}
                tableData={buildTableData(
                    existingItems,
                    costKw,
                    solarPlantSize,
                    GST,
                    isLease,
                    leaseTable
                )}
                options={options}
                handleSelectedProposal={handleSelectedProposal}
            />
        </div>
    );
};

export default Proposal;
