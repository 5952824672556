import { Autocomplete } from "@react-google-maps/api";
import moment from "moment";
import React, { useRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import DatePickerBox from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import { fromLatLng } from "react-geocode";
import { RxCalendar } from "react-icons/rx";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ModalAlt from "../../components/modal/ModalAlt";
import { COUNTER_TIME, ERROR_MSG } from "../../constants/common";
import {
    REMOVE_REDIRECT_PATH,
    SET_ERROR,
} from "../../constants/contexConstant";
import { emailRegEx, pinRegEx } from "../../constants/regularExpression";
import {
    NEW_ENQUIRY_ENDPOINT,
    SIGNUP_CONSUMER_ENDPOINT,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import {
    getCityAddressPinCode,
    getLatLongGeocoder,
} from "../../helpers/helpers";
import useAuth from "../../hooks/useAuth";
import cn from "../../lib/cn";
import { check } from "../../resources/js/images";
import { handleResentOTP } from "../../services/apiRequest";
import auth from "../../services/auth";
import request from "../../services/request";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import TextField from "../../shared/ui/Form/TextField";
import ReusableMap from "../../shared/ui/Map";
import OTP from "../../shared/ui/OTP";
import Stepper from "../onboarding/Stepper";
import { DatePickerWrapper } from "../onboarding/heroDetails";

const center = {
    lat: 18.5204303,
    lng: 73.8567437,
};
const Enquiry = ({ isOpen, onClose }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const { state, dispatch } = useContextState();
    const { isAuth } = useAuth(location?.pathname, dispatch);

    const [loading, setLoading] = useState(false);

    const [markerPointA, setMarkerPointA] = useState(center);
    const siteRef = useRef(null);

    const [currentStep, setCurrentStep] = useState(
        state?.user?.plant?.status ? 3 : state.user ? 2 : 1
    );
    const [getOtp, setGetOtp] = useState(false);
    const [errorMessage, setErrorMsg] = useState("");
    const [countdown, setCountdown] = useState(5);
    const [autoFillAddress, setAutoFillAddress] = useState(
        "Pune, Maharashtra, India"
    );
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        electricalBill: "",
        date: "",
        address: "",
        otp: "",
    });
    const [errors, setErrors] = useState({});

    const [checkEmail, setCheckEmail] = useState(false);
    const [checkPhone, setCheckPhone] = useState(false);
    const [existingEmail, setExistingEmail] = useState(false);
    const [existingPhone, setExistingPhone] = useState(false);
    const [emailErr, setEmailErr] = useState(null);
    const [phoneErr, setPhoneErr] = useState(null);

    // New states for address fields
    const [manualAddress, setManualAddress] = useState({
        projectName: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
    });
    const [useManualAddress, setUseManualAddress] = useState(true);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "" });
    };
    const handleDate = (value) => {
        setFormData({ ...formData, date: value });
        setErrors({ ...errors, date: "" });
    };
    const handleManualAddressChange = (e) => {
        setManualAddress({ ...manualAddress, [e.target.name]: e.target.value });
    };
    const [showResendLink, setShowResendLink] = useState(false);
    const validate = (formData, autoFillAddress, customAddress, verifyOtp) => {
        const newErrors = {};
        if (currentStep === 1) {
            if (!formData.name.trim()) {
                newErrors.name = "Name is required";
            }
            if (!formData.email) {
                newErrors.email = "Email is Required";
            }
            if (formData.email && !emailRegEx.test(formData.email.trim())) {
                newErrors.email = "Email is invalid";
            }
            if (!formData.phoneNumber.trim()) {
                newErrors.phoneNumber = "Phone number is required";
            } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
                newErrors.phoneNumber = "Phone number is invalid";
            }
            if (verifyOtp) {
                if (!formData.otp) {
                    newErrors.otp = "OTP is required";
                } else if (!formData.otp && formData.otp.length !== 6) {
                    newErrors.otp = "Incomplete OTP";
                }
            }
        }
        if (currentStep > 1) {
            if (!formData.electricalBill.trim()) {
                newErrors.electricalBill = "Electricity Bill is required";
            } else if (
                formData.electricalBill &&
                isNaN(formData.electricalBill)
            ) {
                newErrors.electricalBill = "Bill must be valid number";
            }

            if (!autoFillAddress) {
                newErrors.address = "Address is required";
            }
            if (!formData?.date) {
                newErrors.date = "Date is required";
            } else if (moment(formData.date).isValid() === false) {
                newErrors.date = "Date is invalid!";
            } else {
                const currentMoment = moment().startOf("day").unix();
                const maxTimeEndDays = moment()
                    .add(13, "days")
                    .endOf("day")
                    .unix();
                const userMoment = moment(formData.date).startOf("days").unix();
                if (currentMoment > userMoment) {
                    newErrors.date = "Past date is invalid!";
                } else if (maxTimeEndDays < userMoment) {
                    newErrors.date = "Date can't be more than 2 weeks!";
                }
            }
            if (customAddress) {
                if (!customAddress.projectName) {
                    newErrors.projectName = "Project Name is required";
                }
                if (!customAddress.address) {
                    newErrors.address = "Address is required";
                }
                if (!customAddress.city) {
                    newErrors.city = "City is required";
                }
                if (!customAddress.state) {
                    newErrors.state = "State is required";
                }
                if (!customAddress.pincode) {
                    newErrors.pincode = "Pin code is required";
                } else if (
                    customAddress.pincode &&
                    !pinRegEx.test(customAddress.pincode)
                ) {
                    newErrors.pincode = "Invalid pin code";
                }
            }
        }
        return newErrors;
    };

    const handleGetOTP = () => {
        setLoading(true);
        const reqData = {
            name: formData.name,
            email: formData.email,
            phone: `91${formData.phoneNumber}`,
        };
        let endpoint = SIGNUP_CONSUMER_ENDPOINT;
        request
            .post({
                endpoint,
                body: reqData,
            })
            .then((response) => {
                toast.success("OTP has been sent!");
                setErrors({});
                setGetOtp(true);
                setShowResendLink(true);
                setCountdown(COUNTER_TIME);
                setTimeout(() => {
                    setShowResendLink(false);
                }, countdown * 1000);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err || ERROR_MSG);
            });
    };

    const handleSignup = async () => {
        setLoading(true);
        try {
            let reqData = {
                name: formData.name,
                email: formData.email,
                phone: `91${formData.phoneNumber}`,
                referralCode: null,
                password: formData.password,
                otp: formData.otp,
            };
            let endpoint = SIGNUP_CONSUMER_ENDPOINT;
            await request.post({
                endpoint,
                body: reqData,
            });

            await auth.login({
                username: formData.email,
                password: formData.password,
                otpEnable: false,
            });

            setCurrentStep(2);

            dispatch({
                type: REMOVE_REDIRECT_PATH,
            });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error(err || ERROR_MSG);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate(
            formData,
            autoFillAddress,
            useManualAddress ? manualAddress : null,
            getOtp
        );
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            if (currentStep === 1) {
                if (getOtp) {
                    handleSignup();
                } else {
                    handleGetOTP();
                }
            } else if (currentStep === 2) {
                handleCompleteEnquiry();
            }
        }
    };

    const handleCompleteEnquiry = async () => {
        try {
            const geoResult = await getLatLongGeocoder(autoFillAddress);
            let optionalResult = {};
            if (!manualAddress) {
                if (
                    !geoResult?.pinCode ||
                    !geoResult?.city ||
                    !geoResult?.state
                ) {
                    let res = await fromLatLng(geoResult?.lat, geoResult?.lng);

                    optionalResult = {
                        ...getCityAddressPinCode(
                            res.results[0].address_components
                        ),
                    };
                }
            }

            let reqData = {
                date: `${moment(formData.date).unix()}`,
                bill: formData.electricalBill,
            };

            if (useManualAddress) {
                const latLongGeoCoder = await getLatLongGeocoder(
                    `${manualAddress.address}, ${manualAddress.city}, ${manualAddress.state}, ${manualAddress.pincode}`
                );
                reqData.city = manualAddress.city;
                reqData.state = manualAddress.state;
                reqData.pinCode = manualAddress.pincode;
                reqData.address = manualAddress.address;
                reqData.latitude = latLongGeoCoder.lat;
                reqData.longitude = latLongGeoCoder.lng;
            } else {
                reqData.city = geoResult?.city || optionalResult?.city;
                reqData.state = geoResult?.state || optionalResult?.state;
                reqData.pinCode = geoResult?.pinCode || optionalResult?.pinCode;
                reqData.latitude = geoResult?.lat;
                reqData.longitude = geoResult?.lng;
                reqData.address = autoFillAddress;
            }

            setLoading(true);
            await request.authPost({
                endpoint: NEW_ENQUIRY_ENDPOINT,
                body: reqData,
            });
            setLoading(false);
            setCurrentStep(3);
        } catch (error) {
            setLoading(false);
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
        }
    };

    const handlePlaceChanged = async () => {
        const res = await getLatLongGeocoder(siteRef.current.value);
        setAutoFillAddress(res);
        setMarkerPointA(res);
    };

    const resentOtpHandler = async (mode) => {
        const validationErrors = validate(formData);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            let val = `91${formData.phoneNumber}`;
            let credentials = val;
            await handleResentOTP(mode, credentials, setLoading, dispatch);
        }
    };

    const renderStepFields = () => {
        switch (currentStep) {
            case 1:
                return (
                    <>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-1.6 lg:gap-x-[3rem] xl:gap-x-3.2 xl:mt-3.6">
                            <TextField
                                label={"Full Name"}
                                value={formData?.name}
                                onChange={handleChange}
                                name={"name"}
                                type={"text"}
                                errorMsg={errors?.name}
                                required
                            />
                            <div>
                                <TextField
                                    label={"Email Id"}
                                    value={formData?.email}
                                    onChange={handleChange}
                                    name={"email"}
                                    type={"text"}
                                    errorMsg={errors?.email || emailErr}
                                    required
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-1.6 mt-1.6 lg:gap-x-[3rem] xl:gap-x-3.2 xl:mt-3.6 ">
                            <div>
                                <TextField
                                    label={"Phone Number"}
                                    value={formData?.phoneNumber}
                                    onChange={handleChange}
                                    name={"phoneNumber"}
                                    type={"text"}
                                    errorMsg={errors?.phoneNumber || phoneErr}
                                    helperMsg={
                                        "An OTP will be sent to your phone number for verification"
                                    }
                                    required
                                />
                            </div>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-1.6 lg:gap-x-[3rem] xl:gap-x-3.2 xl:mt-3.6">
                            <TextField
                                label={"Bill Amount"}
                                value={formData?.electricalBill}
                                onChange={handleChange}
                                name={"electricalBill"}
                                type={"text"}
                                errorMsg={errors?.electricalBill}
                                required
                            />

                            <div className="w-full">
                                <div className="flex flex-col">
                                    <label
                                        className="text-secondary font-normal text-sm sm:text-base xl:text-lg mb-0.8 "
                                        htmlFor={"date"}
                                    >
                                        Survey Date
                                    </label>
                                    <div
                                        className={` relative px-px py-px  border   rounded-lg border-blue-400 text-btn-s`}
                                    >
                                        <DatePickerWrapper>
                                            <DatePickerBox
                                                onChange={handleDate}
                                                value={formData?.date}
                                                dayPlaceholder="dd"
                                                name="date"
                                                monthPlaceholder="mm"
                                                format="dd/MM/yyyy"
                                                yearPlaceholder="yyyy"
                                                minDate={new Date()}
                                                clearIcon={null}
                                                calendarIcon={
                                                    <RxCalendar size={20} />
                                                }
                                                className={`w-full flex border-none rounded-lg text-primary bg-white h-[40px] md:h-[53px]`}
                                                calendarClassName="relative top-1 !border-2 !border-blue-400 bg-white rounded-lg"
                                                wrapperClassName={`bg-white !border-none w-full`}
                                                inputClassName="text-primary border-none w-full"
                                            />
                                        </DatePickerWrapper>
                                    </div>
                                    {errors.date && (
                                        <span className="text-red text-btn-s">
                                            {errors.date}*
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 mt-2 ">
                            <TextField
                                label="Project Name"
                                placeholder="Project Name"
                                value={manualAddress.projectName}
                                onChange={handleManualAddressChange}
                                name="projectName"
                                errorMsg={errors.projectName}
                            />
                        </div>
                        <div className="grid grid-cols-1 mt-2 ">
                            {!useManualAddress ? (
                                isLoaded ? (
                                    <>
                                        <div>
                                            <label className="text-secondary font-normal text-sm sm:text-base xl:text-lg  ">
                                                Enter Address{" "}
                                                <span className="text-red">
                                                    *
                                                </span>
                                            </label>
                                            <Autocomplete
                                                onPlaceChanged={
                                                    handlePlaceChanged
                                                }
                                            >
                                                <div
                                                    className={cn(
                                                        "relative px-px py-px border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-btn-s bg-custom-gradient mt-0.8",
                                                        {
                                                            "border-red":
                                                                errors.address,
                                                        }
                                                    )}
                                                >
                                                    <input
                                                        className={`border-none w-full  rounded-lg px-1  py-1  lg:py-1.2
                                                        text-primary font-normal text-sm sm:text-base xl:text-base 2xl:text-xl
                    `}
                                                        placeholder="Enter Address"
                                                        label="Enter Your Address"
                                                        id="siteLocationId"
                                                        shadow={false}
                                                        ref={siteRef}
                                                        isError={errors.address}
                                                    />
                                                </div>
                                            </Autocomplete>
                                        </div>

                                        {errors.address && (
                                            <span className="text-red text-btn-s">
                                                {errors.address}*
                                            </span>
                                        )}
                                    </>
                                ) : (
                                    <P>Loading...</P>
                                )
                            ) : (
                                <div className="grid grid-cols-2 gap-x-4 gap-y-2 mt-2">
                                    <TextField
                                        label="Address"
                                        placeholder="Address"
                                        value={manualAddress.address}
                                        onChange={handleManualAddressChange}
                                        name="address"
                                        errorMsg={errors.address}
                                    />
                                    <TextField
                                        label="City"
                                        placeholder="City"
                                        value={manualAddress.city}
                                        onChange={handleManualAddressChange}
                                        name="city"
                                        errorMsg={errors.city}
                                    />
                                    <TextField
                                        label="State"
                                        placeholder="State"
                                        value={manualAddress.state}
                                        onChange={handleManualAddressChange}
                                        name="state"
                                        errorMsg={errors.state}
                                    />
                                    <TextField
                                        label="Pincode"
                                        placeholder="Pincode"
                                        value={manualAddress.pincode}
                                        onChange={handleManualAddressChange}
                                        name="pincode"
                                        errorMsg={errors.pincode}
                                    />
                                </div>
                            )}
                            <div>
                                <button
                                    onClick={() =>
                                        setUseManualAddress(!useManualAddress)
                                    }
                                    type="button"
                                    className="mt-2 text-blue text-xs md:text-sm underline inline-block"
                                >
                                    {useManualAddress
                                        ? "Select address on Google Map"
                                        : "Unable to find address? Enter manually"}
                                </button>
                            </div>
                        </div>
                        {!useManualAddress && (
                            <div className="grid grid-cols-1 gap-1.6 mt-1.6 lg:gap-x-[3rem] xl:gap-x-3.2 xl:mt-3.6">
                                <ReusableMap
                                    center={markerPointA}
                                    dimensions="w-[100%] h-[200px]"
                                    address={autoFillAddress}
                                    callback={() => setIsLoaded(true)}
                                />
                            </div>
                        )}
                    </>
                );
            case 3:
                return (
                    <div className="flex flex-col justify-center items-center mt-5">
                        <img
                            src={check}
                            className="w-44 h-44"
                            alt="check_icon"
                        />
                        <P className={"text-center w-[70%] my-5"}>
                            An email will be sent to your registered email id
                            and we will contact you shortly to confirm your site
                            booking!
                        </P>
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <ModalAlt
            isOpen={isOpen}
            backgroundColor="transparent"
            onClose={onClose}
            innerTimes={false}
            topPadding={"30px"}
        >
            <div className="flex justify-center ">
                <div className="w-full bg-white rounded-1.5 shadow-md p-2 md:py-4.6 md:px-3">
                    <div className="md:mx-2">
                        <Stepper currentStep={currentStep} />
                    </div>

                    <form onSubmit={handleSubmit}>
                        {renderStepFields()}

                        {getOtp && currentStep === 1 && (
                            <>
                                <OTP
                                    otp={formData.otp}
                                    onChange={handleChange}
                                    error={errorMessage}
                                    errorMessage={errors.otp}
                                    handleResentOTP={resentOtpHandler}
                                />
                            </>
                        )}

                        <div className="mt-2">
                            <Button
                                onClick={(e) => handleSubmit(e)}
                                type="submit"
                                className="w-full flex justify-center px-1 sm:px-1.8 2xl:px-2 py-0.8 sm:py-1.2 xl:py-1.2 "
                                disabled={
                                    loading ||
                                    (currentStep === 1
                                        ? !formData?.phoneNumber?.trim() &&
                                          isLoaded
                                        : false) ||
                                    (currentStep === 1 &&
                                        (emailErr || phoneErr))
                                }
                                isLoading={loading}
                            >
                                {currentStep === 1
                                    ? getOtp
                                        ? "Submit OTP"
                                        : "Get OTP"
                                    : currentStep === 2
                                    ? "Submit details"
                                    : "Done"}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </ModalAlt>
    );
};

export default Enquiry;
